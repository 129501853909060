import {Autocomplete, Box, Dialog, DialogContent, DialogTitle, FormHelperText, Stack, TextField} from "@mui/material";
import React, {useContext, useMemo, useState} from "react";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {Upload} from "@mui/icons-material";
import {filter, find} from "lodash";
import {forms} from "../../services/settings.service";
import MenuItem from "@mui/material/MenuItem";
import {GroupHeader, GroupItems} from "../settings/CheckinFieldsToShow";
import {GlobalContext} from "../../state/global";
import ColumnsService, {commonFields} from "../../services/columns.service";
import {fieldTypes} from "../../services/utils";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomFileMultipleUploader from "../../components/CustomFileMultipleUploader";

const MultipleUserUploadFileDialog = ({
                                          selectedForm,
                                          rowSelectionModel,
                                          openConfirmDialog,
                                          handleToggleConfirmDialog,
                                          refetchUsers
                                      }) => {
    const [globalState, dispatch] = useContext(GlobalContext)
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        selectedForm: selectedForm || find(globalState.dataStructures, ['main', true]),
        chosenField: null,
        value: null
    })

    const optionsFields = useMemo(() => {
        if (!globalState.dataStructures)
            return []

        const mainForm = !!state.selectedForm ?
            find(globalState.dataStructures, ['id', state.selectedForm?.id])
            : find(globalState.dataStructures, ['main', true])

        let res = filter(
            ColumnsService.getFieldsFromForm(mainForm),
            function (o) {
                return o.type === fieldTypes.FILE_UPLOAD.type
            }
        )
        res = filter(res, function (o) {
            return !find(commonFields, ['id', o.id])
        })

        //console.log("optionsFields > res:",res)
        return res
    }, [state.selectedForm, globalState.dataStructures]);

    function handleChangeSelectedForm(event) {
        setState({...state, selectedForm: event.target.value, chosenField: null, value: null})
    }

    const [openEditDialog, setOpenEditDialog] = useState(false);

    const handleToggleDialog = () => {
        setOpenEditDialog(!openEditDialog)
    }

    /*useEffect(() => {
        console.log('state:', state)
    }, [state])*/

    return (
        <Box>
            <CustomBackdrop open={loading}/>
            <CustomTooltip title={'Caricamento file multiplo'} children={
                <IconButton size={'small'}
                            onClick={() => handleToggleDialog()}
                >
                    <Upload fontSize={'small'}/>
                </IconButton>
            }/>
            <Dialog open={openEditDialog} onClose={handleToggleDialog} maxWidth={'md'} fullWidth>
                <DialogTitle>
                    {`Caricamento multiplo di file a ${rowSelectionModel.length} partecipanti`}
                </DialogTitle>
                <DialogContent>
                    <Stack mt={2} direction={"row"} spacing={2}>
                        <TextField variant={'outlined'} size={'small'}
                                   select
                                   sx={{width: '60%'}}
                                   id={'form'}
                                   name={'form'}
                                   label={'Form'}
                                   defaultValue={find(globalState.dataStructures, ['main', true])}
                                   value={state.selectedForm}
                                   onChange={handleChangeSelectedForm}
                        >
                            {
                                filter(Object.values(globalState.dataStructures || []), function (o) {
                                    return o.id !== forms.CHECKIN.id
                                }).map((option) => (
                                    <MenuItem key={option.label} value={option} children={
                                        <Stack direction={'row'} spacing={1}>
                                            <div>{option.label}</div>
                                        </Stack>
                                    }/>
                                ))}
                        </TextField>
                        <Stack width={'100%'} direction={'row'} alignItems={'flex-start'}>
                            <Autocomplete
                                fullWidth
                                size={'small'}
                                disableCloseOnSelect
                                groupBy={(option) => option.section}
                                onChange={(event, newValue) => {
                                    if (newValue)
                                        setState({
                                            ...state,
                                            chosenField: newValue
                                        })
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               label="Scegli il campo da modificare"
                                    />}
                                options={optionsFields}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                            />
                        </Stack>
                    </Stack>
                    {state.chosenField &&
                        <Stack mt={2} alignItems={'center'} justifyContent={'center'}>
                            <FormHelperText>Carica {state.chosenField.label}</FormHelperText>
                            <CustomFileMultipleUploader field={state.chosenField.id}
                                                        fileLabel={state.chosenField.label}
                                                        uploaded={state.value}
                                                        users={rowSelectionModel}
                                                        refetch={refetchUsers}
                                                        openConfirmDialog={openConfirmDialog}
                                                        handleToggleConfirmDialog={handleToggleConfirmDialog}

                            />
                        </Stack>
                    }
                </DialogContent>
                {/*!!openEditDialog?.responseMessage &&
                    <Alert elevation={6} variant={"filled"}
                           severity={'error'}
                           sx={{width: '100%'}}>
                        {openEditDialog?.responseMessage || ''}
                    </Alert>*/}
            </Dialog>
        </Box>
    )
}

export default MultipleUserUploadFileDialog