import React, {useState} from "react";
import UserService from "../../services/user.service";
import IconButton from "@mui/material/IconButton";
import {Archive, MoreVert, Save, Unarchive} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {API_URL} from "../../config";

const UserDetailsMenu = ({isAgenzia, archive = true, userId, updateUser, onSuccess, onError}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleToggleConfirmDialog = () => {
        setOpenConfirmDialog(!openConfirmDialog)
        handleCloseUserMenu()
    }

    const handleConfirm = async () => {
        await handleArchiveUser()
    }

    const handleArchiveUser = () => {
        updateUser({
            url: !isAgenzia ? UserService.archiveUserUrl(userId, archive)
                : archive ? `${API_URL}agenzie/${userId}/archive` : `${API_URL}agenzie/${userId}/unarchive`,
            method: "POST"
        })
            .then(() => {
                handleToggleConfirmDialog()
                onSuccess()
            })
            .catch((err) => {
                onError()
            })
    }

    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenUserMenu}>
                <MoreVert/>
            </IconButton>
            <Menu id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseUserMenu}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem onClick={handleToggleConfirmDialog}>
                    <ListItemIcon>
                        {
                            archive ?
                                <Archive fontSize="small" color={'accent'}/>
                                : <Unarchive fontSize="small" color={'accent'}/>
                        }
                    </ListItemIcon>
                    <ListItemText>{archive ? 'Archivia' : 'Ripristina'}</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog open={openConfirmDialog} onClose={handleToggleConfirmDialog}>
                <DialogContent>
                    <DialogContentText>
                        Sei sicuro di voler {archive ? 'archiviare' : 'ripristinare'} l'utente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DialogActions>
                        <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                        <Button onClick={handleConfirm} variant={'submit'}
                                startIcon={
                                    archive ?
                                        <Archive/>
                                        : <Unarchive/>}>
                            {archive ? 'Archivia' : 'Ripristina'}</Button>
                    </DialogActions>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserDetailsMenu