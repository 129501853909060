import {Box, Chip, Divider, Fab, ListItem, Stack, Typography} from "@mui/material";
import {isEmpty} from "lodash";
import {customTheme} from "../theme/customTheme";
import React from "react";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import CustomLoader from "../components/CustomLoader";
import logo from "../assets/images/logo_bicolor_1000px/Logo winston - rosso bianco@1K.png"
import {Refresh} from "@mui/icons-material";

const CheckInStats = () => {
    const [{data: reports, loading: loadingReports, error: errorReports}, fetchStats] = useAxios(
        `${API_URL}checkin/stats`, {method: "GET", useCache: false, manual: false}
    )

    return (<>
        <Stack sx={{background: customTheme.palette.primary.main}}
             height={'50px'} width={'100%'}
             justifyContent={'center'} alignItems={'center'}
               position={'sticky'} top={0} zIndex={1}
        >
            <img src={logo} width={'150px'}/>
        </Stack>
        <Stack p={1}>
            <Box px={1} position={'sticky'} top={50} zIndex={1} sx={{background: 'white'}}>
                <Typography gutterBottom variant={'h3'}>STATISTICHE CHECK-IN</Typography>
            </Box>
            {loadingReports && <CustomLoader/>}
            {
                reports && !isEmpty(reports) &&
                        Object.entries(reports || []).map(([key, value], i) => {
                            return !key.startsWith('br') ?
                                <ListItem key={i}>
                                    <Typography variant={'h6'}><strong>{key}: </strong>
                                        <Chip label={value || 0}
                                              sx={{fontSize: '1rem'}}
                                        />
                                    </Typography>
                                </ListItem>
                                : <Divider key={i} sx={{my:1.5}} flexItem children={<Chip label={value} size={'small'} variant={'outlined'}/>}/>
                        })
            }
        </Stack>
            <Fab onClick={fetchStats}
                color={'primary'}
                size={"large"}
                variant={'action'}>
            <Refresh/>
    </Fab>
        </>
    )
}

export default CheckInStats