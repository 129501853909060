import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyARoHXnFL4ExlUoyThzejWDqIQ3ac_uRms",
    authDomain: "reale-mutua-2024.firebaseapp.com",
    projectId: "reale-mutua-2024",
    storageBucket: "reale-mutua-2024.appspot.com",
    messagingSenderId: "125846704679",
    appId: "1:125846704679:web:c1e2b2a27b89857b8b7402"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}