import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemIcon, Snackbar, Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import CustomLoader from "../CustomLoader";
import ListItemText from "@mui/material/ListItemText";
import {Close, Headset, HeadsetOff, Info} from "@mui/icons-material";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";
import {getColorFromCategoria} from "../../services/utils";
import {customTheme} from "../../theme/customTheme";
import axios from "axios";
import {Alert} from "@mui/lab";
import IconButton from "@mui/material/IconButton";

export function OptionalCheckinUserData({userData, dividerColor = 'white'}) {
    const [{data: checkinFields, loading: loadingSettings, error: errorSettings}] = useAxios(
        `${API_URL}checkin-fields`, {method: "GET", useCache: false, manual: false}
    )

    return (
        <>
            {
                <List>
                    {
                        checkinFields?.map((field, i) => (
                            <div key={i}>
                                <Divider color={dividerColor}/>
                                <ListItem sx={{px:0}}>
                                    {/*<ListItemIcon>
                                    {(userData && userData[field.id]) ?
                                        userData ? [field.id] === 'Si' ? <Restaurant/> : <NoMeals/>}
                                </ListItemIcon>*/}
                                    <ListItemIcon>
                                        {<Chip variant={'accent-outlined'}
                                               size={'small'}
                                               sx={{marginRight: 1}}
                                               label={field.label}
                                        />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={((userData && userData[field.id]) ?
                                            userData[field.id].toString().toUpperCase() : '-')}
                                    />
                                </ListItem>
                            </div>

                        ))
                    }
                    <Divider color={dividerColor}/>
                </List>
            }
            {(loadingSettings) && <CustomLoader/>}
            {(errorSettings) && <Typography>Ops... qualcosa è andato storto</Typography>}
        </>
    );
}

const ConfirmCheckinDialog = ({colorField, open, handleClose, user, fetchUsers, alreadyScanned, info = false}) => {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    async function handleHeadSet(userId, consegna) {
        await axios.get(API_URL + "participantsFisici/" + userId + "/cuffie?consegna="+consegna.toString())
            .then(() => {
                fetchUsers()
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                    handleClose()
                }, 1000)
            }).catch(err => {
                console.log("err:", err)
                setError(err.response?.data?.error || "Errore")
            })
    }

    function handleCloseError() {
        setError(null)
    }

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{
                background: user ? (user[colorField] || getColorFromCategoria(user?.categoria) || 'primary') : 'primary',
                color: user ? customTheme.palette.getContrastText(user[colorField] || getColorFromCategoria(user?.categoria) || customTheme.palette.primary.main) : 'white'
            }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Info color={'primary'} sx={{mr: 2}}/>
                    {info ? "Informazioni"
                        : alreadyScanned ? `QR code già scansionato ${!!user?.checkinScanCount ? (user.checkinScanCount+' volte') : '' }`||""
                            : "Checkin avvenuto con successo"}
                    <IconButton onClick={handleClose} autoFocus>
                        <Close sx={{color: 'white'}}/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{px:1}}>
                <DialogContent id="alert-dialog-description">
                    {!user && <CustomLoader/>}
                    {user ?
                        <Box>
                            <Typography gutterBottom variant={"h5"}>{user?.nome} {user?.cognome}</Typography>
                            <OptionalCheckinUserData userData={user}/>
                            <Stack spacing={1} alignItems={'start'}>
                            {
                                user.checkedInAt ?
                                    <Chip label={`Primo check-in alle ${new Date((user.checkedInAt?._seconds * 1000))
                                        .toLocaleTimeString('it-IT', {hour: 'numeric', minute: 'numeric'})}`}/>
                                    : null
                            }
                            {
                                user.consegnaCuffieAt ?
                                    <Chip label={`Consegnato cuffie alle ${new Date((user.consegnaCuffieAt?._seconds * 1000))
                                        .toLocaleTimeString('it-IT', {hour: 'numeric', minute: 'numeric'})}`}/>
                                    : null
                            }
                            {
                                user.ritiroCuffieAt ?
                                    <Chip label={`Restituito cuffie alle ${new Date((user.ritiroCuffieAt?._seconds * 1000))
                                        .toLocaleTimeString('it-IT', {hour: 'numeric', minute: 'numeric'})}`}/>
                                    : null
                            }
                            </Stack>
                        </Box> : null}
                </DialogContent>
            </DialogContent>
            <Snackbar open={success}>
                <Alert elevation={6} variant={"filled"} severity="success"
                       sx={{width: '100%'}}>
                    Consegna andata a buon fine!
                </Alert>
            </Snackbar>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error"
                       sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
            <DialogActions>
                {user?.lang === 'it' ?
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                    : <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                        <Button
                            disabled={!user?.consegnaCuffieAt || !!user?.ritiroCuffieAt}
                            onClick={() => handleHeadSet(user.id, false)} startIcon={<HeadsetOff/>}>
                            RESTITUZIONE CUFFIE
                        </Button>
                        <Button
                            disabled={/*!user?.checkin || */!!user?.consegnaCuffieAt}
                            onClick={() => handleHeadSet(user.id, true)} startIcon={<Headset/>}>
                            CONSEGNA CUFFIE
                        </Button>
                    </Stack>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmCheckinDialog;
