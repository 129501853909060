import {Chip} from "@mui/material";
import React from "react";

export const FormCompletedCustomChip = ({logistic, completionField, long = false}) => {
    return (
        <Chip
            variant={'outlined'}
            size={'small'}
            color={!!completionField ? 'success' : 'error'}
            label={completionField ?
                logistic ?
                    (long ? 'CONVOCAZIONE inviata in data: ' : "") + (completionField?._seconds ?
                        new Date((completionField?._seconds * 1000))
                            .toLocaleString('it-IT',
                                {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'})
                        : completionField ?
                            new Date(completionField)
                                .toLocaleString('it-IT',
                                    {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'})
                            : "")
                    :((long ? 'Completato in data: ' : "") + (completionField?._seconds ?
                        new Date((completionField?._seconds * 1000))
                            .toLocaleString('it-IT',
                                {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'})
                        : completionField ?
                            new Date(completionField)
                                .toLocaleString('it-IT',
                                    {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'})
                            : ""))
                : logistic ? long ? "CONVOCAZIONE NON inviata" : "NON inviata" : 'Non completato'}
        />
    );
}

export default FormCompletedCustomChip