import React, {useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import {
    Backdrop,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Stack,
    Typography
} from "@mui/material";
import {Check, Delete, DeleteForever, OpenInNew, Upload} from "@mui/icons-material";
import {customTheme} from "../theme/customTheme";
import CustomLoader from "./CustomLoader";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import UserService from "../services/user.service";
import {Alert} from "@mui/lab";
import CustomBackdrop from "./CustomBackdrop";

export const CustomFileMultipleUploader = ({_handleChange, _file, field, fileLabel, uploaded, users, openConfirmDialog, handleToggleConfirmDialog, refetch}) => {
    const [file, setFile] = useState(null)

    const [{data, loading, error}, uploadFiles] = useAxios(
        {}, {manual: true}
    )

    const handleChange = (file) => {
        setFile(file);
    };

    const sendFile = async (e) => {
        e?.preventDefault()
        e?.stopPropagation()
        const formData = new FormData();
        //formData.append('user_id', id);
        if (file !== null) {
            formData.append('file', file)
        }

        formData.append('users', JSON.stringify(users))
        formData.append('field', field)

        uploadFiles({
            url: `${UserService.userDataUrl()}upload/multiple-files`,
            method: "POST",
            data: formData
        })
            .then((res) => {
                console.log("caricato!")
                refetch()
            })
            .catch((err) => console.log("err:", err))
    }

    return (
        <Box sx={{width: {xs: '100%', md: 'min(25rem, 100%)'}, py: 2}}>
            <CustomBackdrop open={loading}/>
            {uploaded ?
                <Stack justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={1}>
                    {/*<Check sx={{color: customTheme.palette.accent.main, fontSize: 32}}/>*/}
                    <Button startIcon={<OpenInNew/>}
                            color={"accent"}
                            variant={'outlined'}
                            href={uploaded}
                            target={'_blank'}
                    >
                        Vedi {fileLabel}
                    </Button>
                </Stack>
                :
                <FileUploader
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF", "zip", "ZIP"]}
                    label={'Carica o trascina il file qui'}
                    hoverTitle={"Rilascia qui"}
                    children={
                        <Stack alignItems={'center'}
                               sx={{
                                   border: `2px dashed ${customTheme.palette.accent.main}`,
                                   borderRadius: '1rem',
                                   cursor: 'pointer',
                                   p: 2
                               }}>
                            {!file ?
                                <Typography textAlign={'center'}>
                                    Nessun file caricato,
                                    <br/>
                                    <u>clicca</u> o trascina il file qui
                                </Typography>
                                :
                                <Typography textAlign={'center'}>
                                    {`File caricato: ${file?.name}`}
                                    <br/>
                                    <u>clicca</u> o trascina il file qui per cambiare file
                                </Typography>
                            }
                        </Stack>
                    }
                />
            }
            {!uploaded &&
                <Box my={1.5} display={"flex"} justifyContent={'center'}>
                    {loading ?
                        <CustomLoader/>
                        : <Button variant={'submit'}
                                  disabled={!file || !!uploaded}
                                  color={'secondary'}
                                  onClick={(e) => handleToggleConfirmDialog((e) => sendFile(e))}

                        >
                            carica file
                        </Button>}
                </Box>}
            {error && <Alert elevation={6} variant={"filled"}
                    severity={'error'}
                    sx={{width: '100%'}}>
                Errore nel caricamento del file
            </Alert>}
        </Box>
    )
}

export default CustomFileMultipleUploader
