import {Diversity1, LocalPolice, WorkspacePremium} from "@mui/icons-material";

export const API_URL = "https://reale-mutua-2024-api-c7qjhnebkq-ew.a.run.app/" // PROD
//export const API_URL = "http://192.168.88.161:8080/";

export const appName = process.env.REACT_APP_APP_NAME || 'Dashboard'

export const roles = {
    ADMIN: {
        icon: <LocalPolice/>,
        label: "Admin",
        id: "ADMIN"
    },
    AGENCY: {
        icon: <Diversity1/>,
        label: "Agenzia",
        id: "AGENCY"
    },
    CLIENT: {
        icon: <WorkspacePremium/>,
        label: "Cliente",
        id: "CLIENT"
    }
}