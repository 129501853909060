import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {Avatar, Button, DialogContent, IconButton} from "@mui/material";
import {appName} from "../../config";
import {useContext, useState} from "react";
import {GlobalContext} from "../../state/global";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";
import {QueryStats} from "@mui/icons-material";
import CustomTooltip from "../CustomTooltip";
import Dialog from "@mui/material/Dialog";
import CheckInStats from "../../pages/CheckInStats";

export default function CheckinAppBar({handleDialogOpen}) {
    const [{data: dataSettings, loading, error}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    const [openStats, setOpenStats] = useState(false)

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color={'secondary'}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Check in
                        <CustomTooltip
                            title={'Vedi le statistiche'}
                            children={<Button size={'small'}
                                              variant={'contained'}
                                              sx={{ml: 2}}
                                             onClick={() => {
                                                 setOpenStats(!openStats)
                                                 //window.open(`${window.location.origin.toString()}/checkin/stats`, '_blank');
                                             }}
                                              endIcon={<QueryStats size={'small'} color={'accent'}/>}
                            >
                                Vedi le statistiche
                                <Dialog open={openStats} onClose={() => setOpenStats(!openStats)}>
                                    <DialogContent>
                                        <CheckInStats/>
                                    </DialogContent>
                                </Dialog>
                            </Button>}/>
                    </Typography>
                    <Typography variant={'body1'} textAlign="center">
                        {dataSettings?.settings?.eventName || ''}
                    </Typography>
                    {<IconButton
                        size="large"
                        aria-label="cerca iscritto"
                        color="inherit"
                        onClick={handleDialogOpen}
                    >
                        <PersonSearchIcon />
                    </IconButton>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
